import React from "react";
import DefaultLayout from "../components/DefaultLayout";
import Hero from "../components/bricks/Hero";
import {Link} from "gatsby";
import Strip from "../components/ui/Strip";
import Col from "../components/ui/Col";
import Document from "../components/Document";
import xcolors from "../lib/xcolors";
import {Text} from "../components/ui/Text";
import Row from "../components/ui/Row";
import Push, {pushClass} from "../components/ui/Push";
import styled from "@emotion/styled";
import mq from "../components/ui/media-queries";
import {css} from "@emotion/core";
import NewsletterForm from "../components/NewsletterForm";
import {SocialLinks} from "../components/Footer";

const ActiveAwareLink = ({className, href, ...p}) =>
  href ? (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a className={className} href={href} {...p} />
  ) : (
    <Link
      getProps={({isCurrent}) => (isCurrent ? {className: `active ${className}`} : {className})}
      {...p}
    />
  );

const NavLink = styled(ActiveAwareLink)({
  display: "block",
  color: xcolors.gray500,
  fontWeight: "bold",
  transitionProperty: "color, box-shadow",
  boxShadow: `inset 0 -0.1rem transparent`,
  ":hover": {
    color: xcolors.purple500,
  },
  "&.active": {
    boxShadow: `inset 0 -0.1rem ${xcolors.purple300}`,
    color: xcolors.purple600,
  },
});

const navBox = css({
  fontSize: "1rem",
  [mq.sm]: {
    top: 0,
    fontSize: "4vw",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: "-0.5rem",
    "> *": {marginBottom: "0.5rem"},
    [`> *:not(:last-child):not(.css-${pushClass.name})`]: {
      marginRight: "3vw",
      marginBottom: "0.5rem",
    },
  },
});

const Navigation = ({years}) => (
  <Col
    bg="white"
    px={4}
    py={5}
    sp={4}
    sticky
    css={{top: "4rem", minWidth: "7.5rem", [mq.sm]: {position: "relative", top: 0, width: "100%"}}}
    align="start"
  >
    <NavLink to="/changelog/">Latest</NavLink>
    <Col sp={3} align="start">
      <Text color="gray400" size={2} preset="bold">
        By year
      </Text>
      <Col sp={2} css={navBox}>
        {years.map(year => (
          <NavLink key={year} to={`/changelog/${year}/`}>
            {year}
          </NavLink>
        ))}
      </Col>
    </Col>
  </Col>
);

const Content = ({contentNode}) => {
  if (contentNode.html) {
    return (
      <Document
        css={{color: xcolors.gray700}}
        dangerouslySetInnerHTML={{__html: contentNode.html}}
      />
    );
  } else {
    return <Document css={{color: xcolors.gray700}}>{contentNode.asReact}</Document>;
  }
};

const Release = ({release}) => {
  return (
    <Col bg="white" px={4} py={5} sp={5}>
      <Row align="baseline" sp={2} pb={1} css={{borderBottom: `1px solid ${xcolors.gray200}`}}>
        {release.title ? (
          <Row align="baseline" sp={0}>
            <Text size={3} color="gray600" preset="bold">
              v{release.version}
            </Text>
            <Text size={5} color="purple600" preset="bold">
              {release.title}
            </Text>
          </Row>
        ) : (
          <Text size={5} color="purple600" preset="bold">
            v{release.version}
          </Text>
        )}
        <Push />
        <Text size={2} color="gray500" preset="bold">
          {release.createdAt}
        </Text>
      </Row>
      <Content contentNode={release.content.childMarkdownRemark} />
    </Col>
  );
};

const Changelog = ({location, pageContext: {years, releases}}) => {
  return (
    <DefaultLayout title="Changelog" description="Latest releases" location={location}>
      <Hero size="sm" title="Changelog">
        <Col sp={4} align="center" style={{maxWidth: "100%"}}>
          <Text color="gray200" size={4} align="center">
            Get the insider look about our journey towards improving video game planning for
            everybody.
          </Text>
          <NewsletterForm placeholder="Enter your email to subscribe" buttonLabel="Subscribe" />
          <Col sp={1} align="center">
            <Text color="gray400" size={1} align="center">
              Follow us here for updates
            </Text>
            <SocialLinks />
          </Col>
        </Col>
      </Hero>
      <Strip size="md" py={7} bg="gray300">
        <Row sp={5} align="start" breakAt={mq.sm}>
          <Navigation years={years} />
          <Col sp={5}>
            {releases.map(release => (
              <Release key={release.id} release={release} />
            ))}
          </Col>
        </Row>
      </Strip>
    </DefaultLayout>
  );
};

export default Changelog;
